<template>
  <div class="admin-layout">
    <Header />
    <slot />
    <Footer />
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import Header from '@/components/sections/admin/Header.vue'
import Footer from '@/components/molecules/Footer.vue'

export default defineComponent({
  name: 'AdminLayout',
  components: {
    Header,
    Footer 
  }
})
</script>

<style lang="scss" scoped>
.admin-layout {
  min-height: 100vh;
}
</style>
